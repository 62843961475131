import { baseRestService } from "@/services/_base/baseRestService";
import settings from "@/settings";

class EnteService extends baseRestService {

    constructor() {
        super();
        this.baseUrl = () => settings.baseUrl;
    }

    async Detail(id: string): Promise<ente.item> {
        return await this.Get<ente.item>(`/api/anagrafica/enti/${id}`);
    }
}

export const enteService = new EnteService();