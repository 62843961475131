import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import GiornalistaAutocomplete from '@/modules/anagrafica/components/giornalistaAutocomplete/giornalistaAutocomplete.vue';
import { creditiSpecialiService } from '../../services/creditiSpecialiService';
import { ElForm } from 'element-ui/types/form';
import settings from '@/settings';
import { periodoFormativoService } from '@/modules/offertaFormativa/services/periodoFormativoService';

@Component({
  components: {
    GiornalistaAutocomplete,
  }
})
export default class dialogCreditiSpeciali extends Vue {
  @Prop()
  manage: creditiSpeciali.item;
  
  command: creditiSpeciali.item = {
    id: null,
    dataProposizione: null,
    tipo: null,
    periodoFormativoId: null,
    anagraficaGiornalistaId: null,
    creditiProposti: null,
    creditiConcessi: null,
    note: null,
    referenteId: null,
    referenteEnteId: null,
    stato: null,
    dataCambioStato: null,
    motivazioneCambioStato: null,
    cambioStatoUserId: null,
    dataEvento: null
  }

  periodoFormativo: periodoFormativo.item = null;
  periodoFormativoId: string = null;
  
  periodiFormativi: periodoFormativo.item[] = [];
  
  aumentoProroga: number = 90;
  aumentoProrogaMillis: number = 1000 * 60 * 60 * 24 * 90;

  async mounted() {
    if(this.manage){
      this.command = Object.assign({}, this.manage);
      this.periodoFormativo = await periodoFormativoService.Detail(this.command.periodoFormativoId);
    }
    else {
      this.periodoFormativo = await periodoFormativoService.Current();
    }
    this.periodoFormativoId = this.periodoFormativo.id;
    this.periodiFormativi = await periodoFormativoService.List();
    this.periodiFormativi.map(a=>{
      let d = new Date();
      a.dataFineProroga = a.dataFineProroga == null ? null : new Date(d.setDate(a.dataFineProroga.getDate() + this.aumentoProroga))
    })
  }

  get selectPerForm(): periodoFormativo.item[]{
    if(this.command != null && this.command.dataEvento != null && (this.periodoFormativoId != null || this.periodoFormativoId != "")){

      let anno = this.command.dataEvento.getFullYear();
      let sogliaProroga = new Date();
      sogliaProroga.setDate(this.command.dataEvento.getDate() + this.aumentoProroga);
      const res = this.periodiFormativi.filter(a=>
        (a.dataInizio <= anno && a.dataFine >= anno)
          ||
        (a.dataFine <= anno && a.dataFineProroga != null && a.dataFineProroga >= sogliaProroga)
      );
      return res;
    }
    return this.periodiFormativi;
  }

  // max crediti concessi per tipologia
  // contribuisce a determinare condizione per salvataggio richiesta
  get creditiSpecialiSoglie() {
    return settings.appSetting.creditiSpeciali.tipoFormazione;
  }

  get tipoCreditoSpeciale() {
    return Object.keys(settings.appSetting.creditiSpeciali.tipoFormazione);
  }


  situazione: creditiSpeciali.situazione = { tipo: null, somma: 0 };
  get situazioneUniqueKey() {
    if (this.command?.anagraficaGiornalistaId && this.command?.periodoFormativoId && this.command?.tipo)
      return `${this.command.anagraficaGiornalistaId}_${this.command.periodoFormativoId}_${this.command.tipo}`;
    return null;
  }

  get creditiConcessiNelTriennio() {
    if (!this.situazione) return 0;
    return this.situazione.somma
  }

  get maxCrediti() {
    if (!this.command || !this.creditiSpecialiSoglie[this.command.tipo])
      return 0;
    const creditiMax = this.creditiSpecialiSoglie[this.command.tipo]
    const creditiResidui = creditiMax - this.creditiConcessiNelTriennio;
    if (this.command.creditiProposti > creditiResidui)
      this.command.creditiProposti = creditiResidui;
    return creditiResidui;
  }

  get abilitaRichiesta() {
    let res: boolean = this.command.anagraficaGiornalistaId != null && this.command.dataEvento != null && this.command.periodoFormativoId != null;
    if(this.command && this.command.id){
      return this.maxCrediti > 0 && res;
    }
    return res;
  };

  @Watch("command.dataEvento")
  async managePeriodoFormativo(n: creditiSpeciali.item, o:creditiSpeciali.item){
    if(n && n != o){
      let anno = this.command.dataEvento.getFullYear();
      let res = this.periodiFormativi.filter(a=>a.dataInizio <= anno && a.dataFine >= anno ).sort((a,b) =>(a.dataInizio - b.dataInizio));
      if(res.length > 0){
        this.periodoFormativo = res[0];
        this.command.periodoFormativoId = this.periodoFormativo.id;
        
      } else {
        this.periodoFormativo = await periodoFormativoService.Current();
      }
      this.command.periodoFormativoId = this.periodoFormativo.id;  

      if( new Date().valueOf() - this.command.dataEvento.valueOf() >= this.aumentoProrogaMillis){
        this.$alert(`Sono decorsi almeno 90 giorni dalla data dell'evento.<br><strong>Il CTS potrebbe rifiutare d'ufficio</strong> la presente richiesta.`, "Attenzione!",{
          confirmButtonText: 'Ok',
            type: "warning",
            dangerouslyUseHTMLString: true
        });
      };
    } 
  }

  @Watch("command.periodoFormativoId")
  managePeriodoFrmativoStato(n: string, o:string){
    if(n && n != o){
      this.periodoFormativo = this.periodiFormativi.filter(a=>a.id == this.command.periodoFormativoId)[0];
      let v = this.selectPerForm;
    } 
  }

  @Watch("situazioneUniqueKey")
  async fetchSituazione(n, o) {
    if (n && n != o) {
      this.situazione = await creditiSpecialiService.Situazione({
        anagraficaGiornalistaId: this.command.anagraficaGiornalistaId,
        periodoFormativoId: this.command.periodoFormativoId,
        tipo: this.command.tipo
      })
    }
    else
      this.situazione = { tipo: null, somma: 0 };
  }

  async save() {
    (this.$refs.form as ElForm).validate(valid => {
      if (valid) {
       
        creditiSpecialiService.Set(this.command)
          .then((result) => {
            this.$emit("change", result);
            this.$message({
              message: `Richiesta inserita correttamente.`,
              showClose: true,
              type: 'success',
              duration: 5 * 1000,
            })
          })
          .catch(() => {
            this.$message({
              message: `Si è verificato un errore nell'inserimento della richiesta.<br>Si prega di compilare nuovamente il form, di controllarne la correttezza e riprovare.`,
              showClose: true,
              dangerouslyUseHTMLString: true,
              type: 'error',
              duration: 5 * 1000
            });
          });
      }
    });

  }

  get formRules() {
    return {
      anagraficaGiornalistaId: [{ required: true, trigger: "blur" }],
      creditiProposti: [{ required: true, trigger: "blur" }],
      tipo: [{ required: true, trigger: "change" }],
      note: [{ required: true, trigger: "blur" }],
      dataEvento: [{ required: true, trigger: "blur" }],
    }
  }
}