import settings from '@/settings';
import { baseRestService } from '@/services/_base/baseRestService'

class CreditiSpecialiService extends baseRestService {
  constructor() {
    super();
    this.baseUrl = () => settings.baseUrl;
  }

  async Count(params?: creditiSpeciali.queryParams): Promise<number> {
    return await this.Get('/api/crediti-speciali/count', params)
  }

  async List(params?: creditiSpeciali.queryParams): Promise<creditiSpeciali.item[]> {
    return await this.Get('/api/crediti-speciali/list', params)
  }

  async Detail(id: string): Promise<creditiSpeciali.item> {
    return await this.Get(`/api/crediti-speciali/${id}`)
  }
  
  async Situazione(params:creditiSpeciali.situazioneParams): Promise<creditiSpeciali.situazione> {
    return await this.Get(`/api/crediti-speciali/situazione`,params)
  }

  async Set(obj: creditiSpeciali.item): Promise<creditiSpeciali.item>{
    return await this.Post<creditiSpeciali.item>('/api/crediti-speciali',obj)
  }

  async Approve(id: string, obj: creditiSpeciali.item): Promise<creditiSpeciali.item> {
    return await this.Put(`/api/crediti-speciali/approvato`, obj, { id })
  }

  async Reject(id: string, obj: creditiSpeciali.item): Promise<creditiSpeciali.item> {
    return await this.Put(`/api/crediti-speciali/rifiutato`, obj, { id })
  }

  async Remove(id: string) {
    return await this.Delete(`/api/crediti-speciali/eliminato`, {id})
  }

}

export const creditiSpecialiService = new CreditiSpecialiService()
