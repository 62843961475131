import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { creditiSpecialiService } from '../../services/creditiSpecialiService';
import { anagraficaService } from '@/modules/anagrafica/services/anagraficaService';
import { userService } from '@/modules/users/services/userService';
import { crogService } from '@/modules/enti/service/crogService'
import settings from '@/settings';
import { periodoFormativoService } from '@/modules/offertaFormativa/services/periodoFormativoService';
@Component({})

export default class dialogValutazioneRichiesta extends Vue {

  @Prop()
  manage: creditiSpeciali.item;

  @Prop({ default: false })
  disabled: boolean

  // max crediti concessi per tipologia
  // contribuisce a determinare condizione per salvataggio richiesta
  get creditiSpecialiSoglie() {
    return settings.appSetting.creditiSpeciali.tipoFormazione;
  }

  command: creditiSpeciali.item = {
    id: null,
    dataProposizione: null,
    tipo: null,
    periodoFormativoId: null,
    anagraficaGiornalistaId: null,
    creditiProposti: null,
    creditiConcessi: null,
    note: null,
    referenteId: null,
    referenteEnteId: null,
    stato: null,
    dataCambioStato: null,
    motivazioneCambioStato: null,
    cambioStatoUserId: null,
  }


  situazione: creditiSpeciali.situazione = { tipo: null, somma: 0 };
  giornalista: giornalisti.item = null;
  referente: user.item = null;
  ente: ente.crog = null;
  periodoFormativo: periodoFormativo.item = null;

  async created() {
    this.command = Object.assign({}, this.manage);

    if (!this.command.creditiConcessi) this.command.creditiConcessi = this.command.creditiProposti

    if (this.manage.periodoFormativoId) {
      this.periodoFormativo = await periodoFormativoService.Detail(this.manage.periodoFormativoId);
    }
    this.situazione = await creditiSpecialiService.Situazione({ anagraficaGiornalistaId: this.manage.anagraficaGiornalistaId, periodoFormativoId: this.manage.periodoFormativoId, tipo: this.manage.tipo } as creditiSpeciali.situazioneParams);
    this.giornalista = await anagraficaService.Detail(this.command.anagraficaGiornalistaId);
    this.referente = (await userService.Detail(this.command.referenteId));
    this.ente = (await crogService.Detail(this.command.referenteEnteId));
  }

  get creditiConcessiNelTriennio() {
    if (!this.situazione) return 0;
    return this.situazione.somma
  }

  get maxCrediti() {
    if (!this.command || !this.creditiSpecialiSoglie[this.command.tipo])
      return 0;
    const creditiMax = this.creditiSpecialiSoglie[this.command.tipo]
    return creditiMax - this.creditiConcessiNelTriennio;
  }

  approve() {
    creditiSpecialiService.Approve(this.command.id, this.command).then((result) => {
      this.$emit('change', result)
    }).catch(() => {
      this.$message({
        message: `Si è verificato un errore nell'approvare la richiesta di crediti speciali.`,
        showClose: true,
        dangerouslyUseHTMLString: true,
        type: 'error',
        duration: 5 * 1000
      });
    })
  }

  reject() {
    creditiSpecialiService.Reject(this.command.id, this.command).then((result) => {
      this.$emit('change', result)
    }).catch(() => {
      this.$message({
        message: `Si è verificato un errore nel rifiutare la richiesta di crediti speciali.`,
        showClose: true,
        dangerouslyUseHTMLString: true,
        type: 'error',
        duration: 5 * 1000
      });
    })
  }
}