import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { userService } from '@/modules/users/services/userService';

@Component({})
export default class referenteAutocomplete extends Vue {
  @Prop()
  value?: string;
  @Prop()
  enteId?: string;

  @Prop({ default: false })
  clearable?: boolean;
  @Prop({ default: false })
  defaultFirstOption: boolean;
  @Prop({ default: 'Ricerca il referente' })
  placeholder?: string;
  @Prop({})
  size?: 'medium' | 'mini' | 'small'
  @Prop({})
  className?: string
  @Prop({ default: false })
  disabled: boolean

  loading: boolean = false;
  options: { value: string; label: string }[] = [];

  public get selected(): string {
    return this.value;
  }
  public set selected(value: string) {
    this.$emit('input', value);
    this.$emit('change', value);
  }

  created() {
    this.initDefaultSelect();
  }

  @Watch('value')
  async valueChange(n, o) {
    if (n !== o) {
      await this.initDefaultSelect();
    }
  }

  @Watch('enteId')
  enteChanged(n, o) {
    if (n !== o) {
      this.options = [];
      this.selected = null;
    }
  }

  async initDefaultSelect() {
    if (this.value) {
      const s = await userService.Detail(this.value);
      if (!s) {
        this.selected = null;
      } else if (this.options.findIndex(f => f.value === s.id) < 0) {
        this.options.push({ label: s.userName, value: s.id });
      }
    } else {
      if (this.selected) this.selected = null;
    }
  }

  async remoteMethod(query) {
    this.loading = true;
    const rows = await userService.AssignableUsers({ search: query, anagraficaEnteId: this.enteId , page: 1, take: 20 });
    this.options = rows.map(m => {
      return { label: m.userName, value: m.id };
    });
    this.loading = false;
  }
}
