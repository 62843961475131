import { Component, Vue } from 'vue-property-decorator';
import filterCreditiSpeciali from '../components/filterCreditiSpeciali/filterCreditiSpeciali.vue';
import { creditiSpecialiService } from '../services/creditiSpecialiService';
import dialogCreditiSpeciali from '../components/dialogCreditiSpeciali/dialogCreditiSpeciali.vue';
import dialogValutazioneRichiesta from '../components/dialogValutazioneRichiesta/dialogValutazioneRichiesta.vue';
import { periodoFormativoService } from '@/modules/offertaFormativa/services/periodoFormativoService';
import { store } from '@/store';

@Component({
  components: {
    filterCreditiSpeciali,
    dialogCreditiSpeciali,
    dialogValutazioneRichiesta
  }
})
export default class CreditiSpecialiView extends Vue {

  listCS: creditiSpeciali.item[] = [];
  detailCS: creditiSpeciali.item = null;
  loading: boolean = false;
  showManage: boolean = false;
  showEvaluate: boolean = false;


  pagination: { pageSizes: number[]; total: number } = {
    pageSizes: [20, 40, 60],
    total: 0
  };

  query: creditiSpeciali.queryParams = {
    periodoFormativoId: null,
    stato: null,
    dataProposizione: null,
    tipo: null,
    anagraficaGiornalistaId: null,
    referenteId: null,
    referenteEnteId: null,
    page: 1,
    take: 20,
  };

  get periodoFormativo(): periodoFormativo.item {
    return store.getters.periodiFormativi.current();
  };

  get periodiFormativi(): periodoFormativo.item[] {
    return store.getters.periodiFormativi.list();
  };
  
  async created() {
    
    await this.fetchData();
  }

  async fetchData() {
    this.loading = true;
    this.pagination.total = await creditiSpecialiService.Count(this.query);
    this.listCS = await creditiSpecialiService.List(this.query);
    // this.listCS.map(a=>{
    //   if(a.dataEvento == null){
    //     a.dataEvento = a.dataCambioStato;
    //   }
    // })
    this.onCancel();
    this.loading = false;
  }

  manage: creditiSpeciali.item = null;
  handleEdit(row: creditiSpeciali.item) {
    this.manage = Object.assign({}, row);
    this.showManage = true;
  }

  get periodiFormativiMap(): Map<string, periodoFormativo.item> {
    const map = new Map<string, periodoFormativo.item>();
    if (this.periodiFormativi != null && this.periodiFormativi.length > 0) {
      for (const periodoFormativo of this.periodiFormativi) {
        if (!map.has(periodoFormativo.id))
          map.set(periodoFormativo.id, periodoFormativo);
      }
    }
    return map;
  }

  async onCancel() {
    this.showManage = false;
    this.showEvaluate = false;
    this.disableEvalutate = false;
    this.manage = null;
  }

  handleEvaluate(row: creditiSpeciali.item) {
    this.manage = row;
    this.showEvaluate = true;
  }

  disableEvalutate = false;
  handleInfo(row: creditiSpeciali.item) {
    this.manage = row;
    this.disableEvalutate = true;
    this.showEvaluate = true;
  }

  async handleDelete(row: creditiSpeciali.item) {
    this.$confirm(`La richiesta di Credito Speciale per l'utente <strong>${row.giornalista?.nomeCompleto}</strong> verrà rimossa definitivamente. Continuare?`, "Attenzione!", {
      confirmButtonText: 'OK',
      cancelButtonText: 'Annulla',
      dangerouslyUseHTMLString: true,
      type: 'warning'
    }).then(async () => {
      this.manage = row;
      await creditiSpecialiService.Remove(row.id)
        .then(() => {
          this.$message({
            message: `Richiesta eliminata correttamente.`,
            showClose: true,
            type: 'success',
            duration: 5 * 1000,
          })
        })
        .catch(() => {
          this.$message({
            message: `Si è verificato un errore nell'eliminazione della richiesta.<br>Si prega di aggiornare la pagina e tentare nuovamente.`,
            showClose: true,
            dangerouslyUseHTMLString: true,
            type: 'error',
            duration: 5 * 1000
          });
        });
      this.fetchData();
    })
  }


  paginationSizeChange(size) {
    this.query.take = size;
    this.query.page = 1;
    this.fetchData();
  }

  paginationPageChange(page) {
    this.query.page = page;
    this.fetchData();
  }

  onFilterChange(query) {
    this.query = query;
    this.fetchData();
  }

  onFilterClean(query) {
    this.query = query;
    this.fetchData();
  }

}