import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { anagraficaService } from '../../services/anagraficaService';

@Component
export default class GiornalistaAutocomplete extends Vue {
  @Prop()
  value?: string;

  @Prop()
  adempienti?: number
  

  @Prop({ default: false })
  clearable?: boolean
  @Prop({ default: false })
  defaultFirstOption: boolean
  @Prop({ default: 'Ricerca un giornalista' })
  placeholder?: string;
  @Prop({})
  size?: 'medium' | 'mini' | 'small'
  @Prop({})
  className?: string
  @Prop({default: false})
  disabled: boolean
  
  loading: boolean = false;
  options: { value: string; label: string }[] = [];

  public get selected(): string {
    return this.value;
  }

  public set selected(value: string) {
    this.$emit("input", value);
    this.$emit("change", value);
  }

  created() {
    this.initDefaultSelect();
  }

  @Watch('value')
  async valueChange(n, o) {
    if (n !== o) {
      await this.initDefaultSelect();
    }
  }

  async initDefaultSelect() {
    if (this.value) {
      const s = await anagraficaService.Detail(this.value);
      if (!s) {
        this.selected = null;
      } else if (this.options.findIndex(f => f.value === s.id) < 0) {
        this.options.push({ label: `${s.nome} ${s.cognome} (${s.codiceFiscale})`, value: s.id });
      }
    } else {
      if (this.selected) this.selected = null;
    }
  }

  async remoteMethod(query) {
    if (query !== '') {
      this.loading = true;

      const users = await anagraficaService.List({ search: query, adempiente: this.adempienti, page: 1, take: 20 });

      this.options = users.map(m => {
        return {
          label: `${m.nome} ${m.cognome} (${m.codiceFiscale})`,
          value: m.id
        };
      });

      this.loading = false;
    } else {
      this.options = [];
    }
  }
}
