import settings from '@/settings';
import { baseRestService } from '@/services/_base/baseRestService'

class UserService extends baseRestService {
  constructor() {
    super();
    this.baseUrl = () => `${settings.baseUrl}/identity/`;
  }

  public async Count(params?: user.queryParams): Promise<number> {
    return await this.Get<number>("users/count", params);
  }

  public async List(params?: user.queryParams): Promise<user.summary[]> {
    return await this.Get<user.summary[]>("users", params);
  }

  public async AssignableUsers(params?: user.queryParams): Promise<user.summary[]> {
    return await this.Get<user.summary[]>("users/assignable", params);
  }

  public async Detail(id: string): Promise<user.item> {
    return await this.Get<user.item>(`users/${id}`);
  }

  public async Create(user: user.item): Promise<user.item> {
    const returnUrl = `${location.origin}/addCredential`;
    return await this.Post<user.item>(`users`, user, { returnUrl });
  }

  public async Update(user: user.item): Promise<user.item> {
    return await this.Put<user.item>(`users/${user.id}`, user);
  }

  public async Remove(id: string): Promise<void> {
    return await this.Delete<void>(`users/${id}`);
  }

  async ToggleLocked(id: string): Promise<user.item> {
    const returnUrl = `${location.origin}`;
    return await this.Get<user.item>(`users/${id}/toggle/locked`, { returnUrl });
  }

  async ToggleEmailConfirm(id: string): Promise<user.item> {
    return await this.Get<user.item>(`users/${id}/toggle/email-confirm`);
  }

  public async Complete(id: string, obj: user.item): Promise<user.item> {
    const returnUrl = `${location.origin}`;
    return await this.Put<user.item>(`/users/complete/${id}`, obj, { returnUrl });
  }

  public async getUserRoles(userId: string): Promise<string[]> {
    return await this.Get(`users/${userId}/roles`);
  }

  public async getUsersByIds(ids: string[]): Promise<user.summary[]> {
    return await this.Post('users/usersByIds', ids);
  }
}

export const userService = new UserService()
