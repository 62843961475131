import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { enteService } from "../service/enteService";
import { ctsService } from "../service/ctsService";
import { cnogService } from "../service/cnogService";
import { crogService } from "../service/crogService";
import { etfService } from "../service/etfService";
import { aziendaService } from "../service/aziendaService";

@Component
export default class EnteAutocomplete extends Vue {
    @Prop()
    value?: string;

    @Prop({ default: () => { return ["cts", "cnog", "crog", "etf", "azienda"] } })
    tipi: string[];

    @Prop({ default: false })
    clearable?: boolean
    @Prop({ default: false })
    defaultFirstOption: boolean
    @Prop({ default: 'Completa il Filtro' })
    placeholder?: string
    @Prop({})
    size?: 'medium' | 'mini' | 'small'
    @Prop({})
    className?: string
    @Prop({ default: false })
    disabled?: boolean;

    loading: boolean = false;
    tipo: "azienda" | "cts" | "crog" | "cnog" | "etf" = null;
    options: { value: string, label: string }[] = [];

    public get selected(): string {
        return this.value;
    }

    public set selected(value: string) {
        this.$emit('input', value);
        this.$emit('change', value);
    }

    @Watch("value")
    async valueChange(n, o) {
        if (n !== o) {
            await this.initDefaultSelect();
        }
    }

    @Watch("tipo")
    async tipoChange(n, o) {
        if (n !== o && n !== this.defaultSelected?.tipo) {
            this.selected = null;
            this.options = [];
            if (n && n !== "") {
                this.remoteMethod(null)
            }
        }
    }


    async created() {
        this.initDefaultSelect();
    }

    defaultSelected: ente.item = null;
    async initDefaultSelect() {
        if (this.value) {
            this.defaultSelected = await enteService.Detail(this.value);
            if (!this.defaultSelected) {
                this.selected = null;
            } else if (this.options.findIndex(f => f.value === this.defaultSelected.id) < 0) {
                this.tipo = this.defaultSelected.tipo;
                this.options.push({ label: this.defaultSelected.nome, value: this.defaultSelected.id })
            }
        } else {
            if (this.selected) this.selected = null;
        }
    }

    get nomeTipo() {
        switch (this.tipo) {
            case 'cts':
                return "del CTS"
            case 'cnog':
                return "del CNOG"
            case 'crog':
                return "del CROG"
            case 'etf':
                return "dell'ETF"
                break;
            case 'azienda':
                return "dell'Azienda"
            default:
                break;
        }
    }

    async remoteMethod(query) {
        this.loading = true;
        let enti = []
        switch (this.tipo) {
            case 'cts':
                enti = await ctsService.List({ text: query, orderField: "Nome", orderDescending: false, page: 1, take: 20, place: null });
                break;
            case 'cnog':
                enti = await cnogService.List({ text: query, orderField: "Nome", orderDescending: false, page: 1, take: 20, place: null });
                break;
            case 'crog':
                enti = await crogService.List({ text: query, orderField: "Nome", orderDescending: false, page: 1, take: 20, place: null });
                break;
            case 'etf':
                enti = await etfService.List({ text: query, orderField: "Nome", orderDescending: false, page: 1, take: 20, place: null });
                break;
            case 'azienda':
                enti = await aziendaService.List({ text: query, orderField: "Nome", orderDescending: false, page: 1, take: 20, place: null });
                break;
            default:
                enti = [];
                console.warn(`E' necessario specificare il tipo ente nella prop "tipo"`)
                break;
        }
        this.options = enti.map(m => { return { label: m.nome, value: m.id } });
        this.$nextTick(() => {
            if (this.options && this.options.length === 1 && (!this.selected || !this.options.map(m => m.value).includes(this.selected))) {
                this.selected = this.options[0].value;
            }
        })
        this.loading = false;
    }
}