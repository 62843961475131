import { Vue, Prop, Component } from "vue-property-decorator";
import RightPanel from "@/components/RightPanel/index.vue";
import EnteAutocomplete from "@/modules/enti/components/enteAutocomplete.vue";
import referenteAutocomplete from "@/modules/corsi/components/referenteAutocomplete/referenteAutocomplete.vue";
import GiornalistaAutocomplete from "@/modules/anagrafica/components/giornalistaAutocomplete/giornalistaAutocomplete.vue";
import settings from "@/settings";
import { periodoFormativoService } from "@/modules/offertaFormativa/services/periodoFormativoService";
import { store } from "@/store";

@Component({
  components: {
    RightPanel,
    EnteAutocomplete,
    referenteAutocomplete,
    GiornalistaAutocomplete
  }
})

export default class filterCreditiSpeciali extends Vue {

  @Prop({ required: true })
  query: creditiSpeciali.queryParams;
  
  periodiFormativi: periodoFormativo.item[] = [];
  async created(){
    const pfs = store.getters.periodiFormativi.list();
    this.periodiFormativi = pfs.filter(f => f.stato === 'chiuso' || f.stato === 'prorogato' || f.stato === 'corrente').sort((a, b) => (b.dataFine + b.dataInizio) - (a.dataFine + a.dataInizio));
  }
  
  get filterActive(): boolean {
    return (this.query &&
      (
        !!this.query.anagraficaGiornalistaId ||
        !!this.query.referenteId ||
        !!this.query.referenteEnteId ||
        !!this.query.dataProposizione || //this.query.stato === "proposto" ||
        !!this.query.periodoFormativoId || 
        !!this.query.tipo 
      ));
  }

  get tipoCreditoSpeciale(){
    return Object.keys(settings.appSetting.creditiSpeciali.tipoFormazione);
  }

  handleFilter() {
    this.query.page = 1;
    (this.$refs.filterPanel as any).show = false;
    this.$emit('change', this.query);
  }

  handleClean() {
    (this.$refs.filterPanel as any).show = false;
    this.$emit('clean', {
      anagraficaGiornalistaId: null,
      referenteId: null,
      referenteEnteId: null,
      dataProposizione: null,
      stato: null,
      periodoFormativoId: null,
      page: 1,
      take: 20
    } as creditiSpeciali.queryParams);
  }
}